export enum Role {
    Admin = 'Admin',
    Device ='Device',
	DeviceDevelopmentDevices = 'Device_Development_Devices',
	DeviceDslTestLab = 'Device_DSL_Test_Lab',
	DeviceTrialist = 'Device_Trialist',
	DeviceGroup = 'DeviceGroup',
	DeviceGroupAdmin = 'DeviceGroupAdmin',
	Firmware = 'Firmware',
	DeviceMaudResearchTrials = 'Device_MAUD_Research_Trials',
	ReadOnly = 'ReadOnly',
	Report = 'Report',
	Suota = 'Suota'
}
